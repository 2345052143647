import CrudService from "@/services/shared/http/CrudService";

export default class TopicService extends CrudService {
  constructor(params = {}) {
    super("topic", params);
  }
  async index(params = {}) {
    return await this.api.GET('topic/get-list', params)
  }
}
