<template>
  <div class="">
    <TopicList/>
  </div>
</template>

<script>
import TopicList from "@/views/components/topic/TopicList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
  name: "Index",
  components: {
    TopicList,
    GoodTableSsr
  }
}
</script>

<style scoped lang="scss">

</style>
